<template>
  <b-modal
    id="modal-create-lease-type"
    ref="modal-create-lease-type"
    centered="centered"
    :title="(leaseType.id ? 'Modifier' : 'Ajouter') + ` un type de bail`"
    @ok="create"
  >
    <div v-if="isCreatingLeaseType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du bail...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreateLeaseType">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="leaseType.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Description" label-for="notes" class="w-100">
            <b-form-textarea
              id="description"
              v-model="leaseType.description"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="leaseType.id && !archive"
          variant="outline-danger"
          @click="archiveLeaseTypeLocal(leaseType.id, leaseType.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="leaseType.id && archive"
          variant="outline-warning"
          @click="restoreLeaseTypeLocal(leaseType.id, leaseType.label)"
          ><feather-icon icon="ArrowDownIcon"
        /></b-button>
        <b-button
          class="float-right"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="ok()"
          >{{ leaseType.id ? "Modifier" : "Ajouter" }}</b-button
        >
        <b-button
          class="float-right mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      leaseType: {
        label: "",
        description: "",
      },
    };
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupCreateLeaseTypeEvent(id) {
      if (id > 0 && !this.archive) {
        this.getLeaseTypeById({
          leaseTypeId: id,
        }).then((res) => {
          this.leaseType = res;
        });
      } else if (id > 0 && this.archive) {
        this.getLeaseTypeArchivedById({
          leaseTypeId: id,
        }).then((res) => {
          this.leaseType = res;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.leaseType = {
        label: null,
      };
    },
    closeModal() {
      this.$emit("closePopupLeaseType", true);
      this.$bvModal.hide("modal-create-lease-type");
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateLeaseType.validate().then((success1) => {
        if (success1) {
          if (this.leaseType.id) {
            this.updateLeaseType({
              leaseType: this.leaseType,
            }).then((res) => {
              this.closeModal();
            });
          } else {
            this.createLeaseType({
              leaseType: this.leaseType,
            }).then((res) => {
              this.closeModal();
            });
          }
        }
      });
    },
    archiveLeaseTypeLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce type de bail en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le type de bail "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveLeaseTypes({ leaseTypeIds: [id] }).then((res) => {
              this.closeModal();
            });
          }
        });
    },
    restoreLeaseTypeLocal(id, label) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser ce type de bail dans tous les modules de votre application",
          {
            title:
              'Êtes-vous sûr de vouloir restaurer le type de bail "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreLeaseType({ leaseTypeIds: [id] }).then((res) => {
              this.closeModal();
            });
          }
        });
    },
    ...mapActions([
      "createLeaseType",
      "updateLeaseType",
      "getLeaseTypeById",
      "archiveLeaseTypes",
      "restoreLeaseTypes",
      "getLeaseTypeArchivedById",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingLeaseType"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
